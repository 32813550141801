const STATES = {
  computed: {
    STATES() {
      return {
        utilizador: [
          {
            estado: "1",
            nome: this.$t("actions.active"),
            color: "light-green darken-1",
            icon: "check_circle",
            classe: "ativo"
          },
          {
            estado: "0",
            nome: this.$t("actions.inactive"),
            color: "red lighten-1",
            icon: "cancel",
            classe: "inativo"
          }
        ],
        obra: [
          {
            estado: "1",
            nome: this.$t("actions.concluida"),
            color: "light-green darken-1",
            icon: "check_circle",
            classe: "ativo"
          },
          {
            estado: "0",
            nome: this.$t("actions.pendente"),
            color: "red lighten-1",
            icon: "cancel",
            classe: "inativo"
          }
        ],
        at: [
          {
            estado: "0",
            nome: this.$t("actions.pendente"),
            color: "red lighten-1",
            icon: "cancel",
            classe: "inativo"
          },
          {
            estado: "1",
            nome: this.$t("actions.agendada"),
            color: "cyan darken-1",
            icon: "event",
            classe: "entregue"
          },
          {
            estado: "2",
            nome: this.$t("actions.concluida"),
            color: "light-green darken-1",
            icon: "check_circle",
            classe: "ativo"
          },
          {
            estado: "3",
            nome: this.$t("actions.paga"),
            color: "amber",
            icon: "mdi-check-all",
            classe: "pendente"
          }
        ]
      }
    }
  }
}

export default STATES
